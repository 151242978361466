<template>
    <div id="intro" class="container">
        <button class="close-widget" @click="$emit('close-widget')"
                v-if="!$parent.isEmbedded"><i class="icon-close"></i></button>

        <div class="card-preview">
            <img class="display" src="../../../assets/img/card-display.svg"/>
            <img class="envelope" src="../../../assets/img/envelop.png" width="549" height="273"/>
            <img class="card" :src="$parent.settings.preview_url" width="527" height="258"/>
        </div>

        <div class="content">
            <h1>{{ $t('intro.title', [$parent.settings.name]) }}</h1>

            <ul>
                <li>
                    <i class="icon-paperplane branding-color"></i>
                    <div class="item">
                        <div>{{ $t('intro.email') }}</div>
                    </div>
                </li>
                <li>
                    <i class="icon-shipment branding-color"></i>
                    <div class="item">
                        <div>{{ $t('intro.postal') }}</div>
                        <small class="branding-text-success">{{ dynamicShippedBeforeLabel }}</small>
                    </div>
                </li>
                <li>
                    <i class="icon-chat-group branding-color"></i>
                    <div class="item">
                        <div>{{ $t('intro.personalize') }}</div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="call-to-action">
            <button @click="$emit('close-intro')" class="button button-primary branding-button-background">
                {{ $t('button.callToAction') }}
            </button>

            <div class="payment-methods">
                <img v-for="method in $parent.settings.payment_methods" v-if="method.reference !== 'card'"
                     :src="method.img" :class="`pm-icon-${method.reference}`" :alt="method.name"/>
                <img v-for="network in $parent.settings.payment.card_networks" v-if="cardsAccepted"
                     :src="network.logo"
                     :alt="network.name"/>
            </div>

            <a href="https://gifty.nl" target="_blank">
                <img src="../../../assets/img/logo.svg" width="40" height="16" class="logo"/>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            x: 0,
            state: '',
        };
    },
    computed: {
        dynamicShippedBeforeLabel() {
            const postage = this.$parent.calculatePostageMoment();

            return this.$t('intro.shippingEstimate.' + postage);
        },
        cardsAccepted() {
            return this.$parent.settings.payment_methods.filter(item => item.reference === 'card').length;
        },
    },
};
</script>
