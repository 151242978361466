<template>
    <div id="payment" class="container">
        <div class="content">
            <top-bar @prev-view="$emit('prev-view')" @close-widget="$emit('close-widget')"></top-bar>

            <h1><i class="icon-giftcard branding-color"></i>{{ $t('payment.title', [$parent.settings.name]) }}</h1>

            <div class="form-group">
                <table class="confirmation-table" ref="confirmTable">
                    <tbody>
                    <template v-for="(giftcard, index) in order.giftcards">
                        <tr :key="index">
                            <td v-if="!$parent.giftcardExamplesThumbs[index]">
                                <div class="preview-img static-preview"><img
                                    :src="settings.preview_url_small" width="42"
                                    height="30"/></div>
                            </td>
                            <td v-else>
                                <div class="preview-img" @click="toggleGiftcardDetail(index)"><img
                                    :src="makeImageFromBase64($parent.giftcardExamplesThumbs[index])" width="42"
                                    height="30"/></div>
                            </td>
                            <td>{{ giftcard.quantity }}x</td>
                            <td v-if="giftcard.package_id === null">{{ $t('card') }} {{ giftcard.value | money }}</td>
                            <td v-else>{{ giftcard.title }}</td>
                            <td>{{ giftcard.value * giftcard.quantity | money }}</td>
                        </tr>
                        <tr class="detail" v-if="showGiftcardDetail === index">
                            <td colspan="4">
                                <div class="detail-container">
                                    <loader v-show="!$parent.giftcardExamples[index]"></loader>
                                    <image-slider v-show="$parent.giftcardExamples[index]"
                                                  :images="$parent.giftcardExamples[index]"
                                                  @close="showGiftcardDetail = false"></image-slider>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <tr v-if="order.wrapping">
                        <td>
                            <div class="preview-img wrapping-preview">
                                <img :src="order.wrapping.image" width="42"
                                     height="30"/></div>
                        </td>
                        <td>{{ totalGiftcards }}x</td>
                        <td v-if="order.wrapping.price !== 0">{{ $t('wrapping') }} {{
                                order.wrapping.name.toLowerCase()
                            }}
                        </td>
                        <td v-else>{{ order.wrapping.name }}</td>
                        <td>{{ order.wrapping.price * totalGiftcards | money }}</td>
                    </tr>
                    <tr v-if="order.personalize !== 'no' && settings.personalization.costs > 0">
                        <td colspan="3">{{ $t('personalization') }}</td>
                        <td>{{ personalizationCosts() | money }}</td>
                    </tr>
                    <tr v-if="order.shipment === 'post'">
                        <td colspan="3">
                            {{ $t('payment.fees') }}
                            <div>
                                <small>{{ $t('shipment.methods.' + order.delivery_method) }}</small>
                            </div>
                        </td>
                        <td>{{ shippingCosts(order.shipment, order.delivery_method) | money }}</td>
                    </tr>
                    <tr v-if="order.coupon_value > 0">
                        <td colspan="3">{{ $t('coupon') }}</td>
                        <td>- {{ order.coupon_value | money }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colspan="3">{{ $t('total') }}</td>
                        <td>{{ orderTotalMinDiscount | money }}</td>
                    </tr>
                    </tfoot>
                </table>
            </div>

            <div class="form-group">
                <div class="checkbox-group">
                    <input id="checkbox" type="checkbox" v-model="coupon_apply">
                    <label for="checkbox">{{ $t('checkbox.coupon') }}</label>
                </div>
            </div>

            <div class="form-group" v-if="coupon_apply">
                <div class="coupon-form">
                    <div class="input-group">
                        <input id="coupon" type="text" v-model="coupon" minlength="6" maxlength="20">
                        <label for="coupon">{{ $t('label.couponInput') }}</label>
                    </div>

                    <div class="input-group">
                        <button class="button button-primary branding-button-background button-small button-inline"
                                @click="handleCoupon"
                                :class="{'button-disabled': !!!couponCheckable || coupon_loading, 'button-loading': coupon_loading }">
                            {{ $t('button.redeem') }}
                        </button>
                    </div>
                </div>

                <div class="text-notice" v-if="coupon_error" v-html="coupon_error"></div>
            </div>

            <p v-html="confirmationText"></p>

            <div v-if="orderTotalMinDiscount > 0">
                <div v-if="settings.payment_methods.length">
                    <h2>{{ $t('payment.method') }}</h2>
                    <div id="payment-method" ref="payment" class="form-group">
                        <div class="card-group card-list">
                            <div v-for="method in settings.payment_methods"
                                 class="card"
                                 @click="setPayment(method.reference)"
                                 :class="{'card-selected': order.payment_method === method.reference}">
                                <div class="card-button">
                                    <div class="card-icon">
                                        <img :src="method.img" :class="`pm-icon-${method.reference}`" :alt="method.name"
                                             :title="method.name">
                                    </div>
                                    {{ method.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="order.payment_method === 'ideal'">
                    <div ref="bank" id="bank-selection" class="form-group">
                        <div class="card-group">
                            <div v-for="bank in getBanks" class="card" @click="setBank(bank.id)"
                                 :class="{'card-selected': order.payment_bank === bank.id}" :key="bank.id">
                                <div class="card-button">
                                    <img :src="bank.logo" :alt="bank.name" :title="bank.name">
                                </div>
                            </div>
                        </div>

                        <button class="button" v-if="!order.all_banks_visible" @click="order.all_banks_visible = true">
                            {{ $t('button.banks') }}
                        </button>
                    </div>
                </div>

                <div v-show="order.payment_method === 'sofort' || order.payment_method === 'klarna'">
                    <div class="form-group" v-show="order.payment_method === 'sofort'">
                        <div class="alert danger-alert">
                            {{ $t('label.sofortNotice') }}
                        </div>
                    </div>

                    <div ref="paymentCountries" class="form-group">
                        <div class="card-group card-list">
                            <div v-for="country in getPaymentCountries" class="card" @click="setPaymentCountry(country)"
                                 :class="{'card-selected': order.account_country === country}" :key="country">
                                <div class="card-button">{{ $t(`country.${country}`) }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-show="order.payment_method === 'bancontact' || order.payment_method === 'giropay'"
                    class="form-group">
                    <div class="input-group">
                        <input id="account_owner" type="text" v-model="order.account_owner" minlength="3"
                               required>
                        <label for="account_owner">{{ $t('label.paymentOwner') }}</label>
                    </div>
                </div>

                <div v-show="order.payment_method === 'card'" class="form-group">
                    <div class="input-group">
                        <div class="card-element" id="card-element" ref="cardElement"></div>
                    </div>
                </div>
            </div>

            <div ref="terms_conditions" class="form-group">
                <div class="checkbox-group">
                    <input id="terms_conditions" type="checkbox" v-model="order.terms_conditions">
                    <i18n tag="label" for="terms_conditions" path="checkbox.tos">
                        <a slot="tos" href="#" @click.prevent="toggleTermsConditions">{{ $t('payment.tos') }}</a>
                        <a slot="privacy" href="#" @click.prevent="togglePrivacyStatement">{{
                                $t('payment.privacy')
                            }}</a>
                    </i18n>
                </div>
            </div>
        </div>

        <p class="text-notice" v-if="order_error" v-html="order_error"></p>

        <p v-if="orderToLow" class="text-notice">
            {{ $t('payment.insufficient', [$money(settings.order_min)]) }}
        </p>

        <next-view-button :disabled="!viewValidates"
                          field="input"
                          :loading="paymentStarted"
                          :onClick="processOrder">
            <i class="icon-lock"></i>
            {{ $t('button.securePayment') }}
        </next-view-button>
    </div>
</template>

<script>
import ImageSlider from '../../elements/ImageSlider';
import Loader from '../../elements/Loader';
import StripePayments from '../../../utils/StripePayments';

export default {
    components: {
        ImageSlider,
        Loader,
    },
    data() {
        return {
            order: this.$parent.$data.order,
            settings: this.$parent.settings,
            giftcardsTotal: this.$parent.giftcardsTotal,
            giftcardsWithoutFreeShipping: this.$parent.giftcardsWithoutFreeShipping,
            totalGiftcards: this.$parent.totalGiftcards,
            termsConditionsView: false,
            paymentStarted: false,
            showGiftcardDetail: false,
            coupon: '',
            coupon_apply: false,
            coupon_error: false,
            coupon_loading: false,
            order_error: false,
            credit_card_filled: false,
        };
    },

    computed: {
        orderToLow() {
            if (this.orderTotal < this.settings.order_min) {
                return true;
            }

            return false;
        },
        couponCheckable() {
            if (this.coupon.length < 8) {
                return false;
            }

            return true;
        },
        viewValidates() {
            if (this.orderToLow) {
                return false;
            }

            if (this.paymentStarted) {
                return false;
            }

            if (this.orderTotalMinDiscount === 0 && this.order.terms_conditions) {
                return true;
            }

            if (this.order.payment_method === 'ideal' && !this.order.payment_bank) {
                return false;
            }

            if (
                (this.order.payment_method === 'bancontact' || this.order.payment_method === 'giropay')
                && this.order.account_owner.length < 3) {
                return false;
            }

            if (
                this.order.payment_method === 'sofort' && this.order.account_country === '') {
                return false;
            }

            if (
                this.order.payment_method === 'klarna' && this.order.account_country === '') {
                return false;
            }

            if (this.order.payment_method === 'card' && this.credit_card_filled === false) {
                return false;
            }

            return this.order.terms_conditions && this.order.payment_method;
        },
        orderTotal() {
            let total = this.giftcardsTotal;

            // Wrapping costs
            if (this.order.wrapping) {
                const wrappingCosts = this.order.wrapping.price;
                total += (this.totalGiftcards * wrappingCosts);
            }

            // Shipment costs
            if (this.order.shipment) {
                const shippingCosts = this.shippingCosts(
                    this.order.shipment,
                    this.order.delivery_method,
                );

                total += shippingCosts;
            }

            // Personalization costs
            if (this.order.personalize !== 'no' && this.settings.personalization.costs > 0) {
                total += this.personalizationCosts();
            }

            return total;
        },
        orderTotalMinDiscount() {
            let total = this.orderTotal;

            // Coupon discount
            if (this.order.coupon_value > 0) {
                total -= this.order.coupon_value;

                if (total < 0) {
                    total = 0;
                }
            }

            return total;
        },
        confirmationText() {
            let text = '';

            if (this.order.shipment === 'post') {
                text += this.$t('payment.helptext.mail', {
                    estimate_delivery: this.$t(`intro.dayEstimate.${this.$parent.calculateDeliveryDay(this.order.delivery_selected)}`),
                    street: `${this.order.address.street} ${this.order.address.house_number}`,
                    city: this.order.address.city,
                });
            }

            if (this.order.shipment === 'email') {
                text += this.$t('payment.helptext.email', [this.order.address.email]);
            }

            if (this.order.invoice) {
                text += ` ${this.$t('payment.helptext.invoice', [this.order.address.email])}`;
            }

            return text;
        },
        getPaymentCountries() {
            return this.settings.payment.payment_countries;
        },
        getBanks() {
            if (this.order.all_banks_visible) {
                return this.settings.payment.ideal_banks;
            }

            return this.settings.payment.ideal_banks.slice(0, 6);
        },
    },

    methods: {
        setPayment(method) {
            this.order.payment_method = method;

            this.$nextTick(() => {
                this.$scrollTo(this.$refs.payment, 500, {
                    container: this.$parent.$refs.scrollable,
                });
            });
        },
        setBank(id) {
            this.order.payment_bank = id;
            this.$root.sandboxReference.giftyPaymentBus.iDEALBank = id;

            this.$nextTick(() => {
                this.$scrollTo(this.$refs.bank, 500, {
                    container: this.$parent.$refs.scrollable,
                });
            });
        },
        setPaymentCountry(country) {
            this.order.account_country = country;
            this.$root.sandboxReference.giftyPaymentBus.sofortCountry = country;

            this.$nextTick(() => {
                this.$scrollTo(this.$refs.paymentCountries, 500, {
                    container: this.$parent.$refs.scrollable,
                });
            });
        },
        shippingCosts(shipment, deliveryMethod) {
            return this.$parent.shippingCosts(shipment, deliveryMethod);
        },
        toggleTermsConditions() {
            // this.termsConditionsView = !this.termsConditionsView;
            this.$emit('set-view', 'terms_conditions');
        },
        togglePrivacyStatement() {
            // this.termsConditionsView = !this.termsConditionsView;
            this.$emit('set-view', 'terms_conditions', false, 'privacy_statement');
        },
        personalizationCosts() {
            if (this.settings.personalization.sum) {
                return this.settings.personalization.costs * this.order.giftcards.length;
            }

            return this.settings.personalization.costs;
        },
        handleCoupon() {
            this.coupon_loading = true;

            window.Gifty.axios.post(`${process.env.API_URL}/coupon`, {
                coupon: this.coupon,
                company_id: this.$parent.settings.company_id,
                order_value: this.orderTotal,
            })
                .then(({ data }) => {
                    this.order.coupon = data.data.coupon;
                    this.order.coupon_value = data.data.amount;
                    this.coupon = '';
                    this.coupon_loading = false;
                    this.coupon_error = false;
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        const errors = error.response.data.errors;
                        this.coupon_error = errors[Object.keys(errors)[0]][0];
                    } else {
                        this.coupon_error = this.$t('payment.coupon.invalid');
                    }

                    this.coupon_loading = false;
                });
        },
        processOrder() {
            this.paymentStarted = true;

            const spaces = new RegExp(' ', 'g');

            if (this.order.address && typeof this.order.address.zip === 'string') {
                this.order.address.zip = this.order.address.zip.replace(spaces, '');
            }
            if (this.order.address_invoice && typeof this.order.address_invoice.zip === 'string') {
                this.order.address_invoice.zip = this.order.address_invoice.zip.replace(spaces, '');
            }
            this.order.language = window.Gifty.locale;
            this.order_error = false;

            window.Gifty.axios.post(`${process.env.API_URL}/order`, this.order)
                .then(({ data }) => {
                    this.order.order_id = data.order;

                    if (data.status !== undefined && data.status === 'completed') {
                        localStorage.setItem('gifty.activeOrder', this.order.order_id);
                        sessionStorage.setItem('gifty.order', JSON.stringify(this.order));

                        this.$parent.checkOpenOrder(this.order.order_id);
                    }

                    const returnUrl = this.order.return_url;
                    const psp = new StripePayments(
                        this.$root.sandboxReference.giftyPaymentBus,
                        data.customer_secret,
                        returnUrl,
                    );
                    let billingDetails = {
                        email: this.order.address.email,
                        address: {},
                    };

                    if (this.order.shipment === 'post') {
                        billingDetails.address = {
                            line1: `${this.order.address.street} ${this.order.address.house_number}`,
                            postal_code: this.order.address.zip,
                            city: this.order.address.city,
                            country: this.order.address.country,
                        };
                    }

                    if(this.order.account_country || this.order.address.country) {
                        billingDetails.address.country = this.order.account_country || this.order.address.country;
                    }

                    if (this.order.account_owner || this.order.address.name) {
                        billingDetails.name = this.order.account_owner || this.order.address.name;
                    }

                    psp.confirmPayment(this.order.payment_method, billingDetails)
                        .then((result) => {
                            if (result.paymentIntent) {
                                const action = result.paymentIntent.next_action;

                                localStorage.setItem('gifty.activeOrder', this.order.order_id);
                                sessionStorage.setItem('gifty.order', JSON.stringify(this.order));

                                if (action && action.type === 'redirect_to_url') {
                                    /**
                                     *  Push #gifty.order to the history state. This way we can
                                     *  catch when a user goes back in the browser
                                     */
                                    let urlHistory = returnUrl;
                                    if (!(top.window.parent.location.hash === '#gifty.order')) {
                                        urlHistory += '#gifty.order';
                                    }

                                    top.window.history.pushState({}, '', urlHistory);
                                    top.window.location.href = action.redirect_to_url.url;

                                    return;
                                }

                                this.$parent.checkOpenOrder(this.order.order_id);
                            }

                            if (result.error) {
                                this.order_error = result.error.message;

                                this.paymentStarted = false;
                            }
                        })
                        .catch(() => {
                            this.paymentStarted = false;
                        });
                })
                .catch((error) => {
                    this.paymentStarted = false;

                    console.error(error)

                    if (error.response?.data?.errors) {
                        const errors = error.response.data.errors;
                        this.order_error = errors[Object.keys(errors)[0]][0];
                    } else if (error.response?.data?.message) {
                        this.order_error = error.response.data.message;
                    }

                    this.failedDNSCheckValidation(error.response?.data);
                });
        },
        failedDNSCheckValidation(data) {
            /**
             * Check if the error present is in fact the DNSCheckValidation error
             */
            if (data && typeof data?.errors !== 'undefined' &&
                typeof data.errors['address.email'] !== 'undefined' &&
                data.errors['address.email'].length) {
                /**
                 * Sets the view to shipment
                 */
                this.$parent.setView('shipment', false, (refs) => {
                    /**
                     * Interval to wait and check until all needed template elements are loaded
                     */
                    const interval = setInterval(() => {
                        /**
                         * If all elements are loaded (order important)
                         */
                        const shipment = refs.component;
                        if (shipment !== undefined &&
                            shipment.$refs.nextViewButton !== undefined &&
                            shipment.$refs.emailInput !== undefined) {
                            /**
                             * Clear interval for obvious reasons
                             */
                            clearInterval(interval);

                            /**
                             * Sets error to show to the guest
                             */
                            const nextViewButton = shipment.$refs.nextViewButton;
                            const emailInput = shipment.$refs.emailInput;
                            nextViewButton.locateElement(emailInput);
                            nextViewButton.setErrorClass(emailInput);
                        }
                    }, 50);
                });
            }
        },
        toggleGiftcardDetail(index) {
            if (this.$parent.giftcardExamplesStatus !== 'done') {
                this.$parent.getGiftcardBigPreviews();
            }

            if (this.showGiftcardDetail === index) {
                this.showGiftcardDetail = false;
            } else {
                this.showGiftcardDetail = index;
            }
        },
        makeImageFromBase64(imageData) {
            return `data:image/png;base64,${imageData}`;
        },
    },

    async mounted() {
        // Initialize Stripe elements
        await this.$root.sandboxReference.giftyPaymentBus.initialize();

        const postalCode = this.order.address_invoice.zip ?
            this.order.address_invoice.zip : this.order.address.zip;

        await this.$root.sandboxReference.giftyPaymentBus.card.mount(this.$refs.cardElement);
        await this.$root.sandboxReference.giftyPaymentBus.card.update({ value: { postalCode } });

        this.$root.sandboxReference.giftyPaymentBus.card.on('change', (event) => {
            if (event.error) {
                this.credit_card_filled = false;
            } else {
                this.credit_card_filled = true;
            }
        });

        if (this.$parent.$data.view_ref) {
            this.$nextTick(() => {
                this.$parent.$refs.scrollable.scrollTop = this.$refs[this.$parent.$data.view_ref]
                    .offsetTop;

                this.$parent.$data.view_ref = false;
            });
        } else {
            this.$parent.$refs.scrollable.scrollTop = 0;
        }
    },
};
</script>
